import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Container from '../components/container'

import style from './legal.module.css'

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query PrivacyQuery {
      markdownRemark(frontmatter: {path: {eq: "/privacy/" }}) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
        }
      }
    }
  `)

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return(
    <Layout>
      <SEO
        title='Privacy Policy and GDPR'
        description="The Privacy Policy and GDPR Policy for Tweeker, a simple ab and multivariate testing tool. We respect your privacy and don't store any PII or use your data for sales or marketing."
        canonical='https://tweeker.io/privacy/'
      />
      <Header  />
      <Container>
        <h2 className={style.title}>{frontmatter.title}</h2>
        <p className={style.date}>Effective Date: {frontmatter.date}</p>
        <div
          className={style.container}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Container>
    </Layout>
  )
}

export default PrivacyPage